<template>
  <div class="material">
    <van-nav-bar title="素材中心" left-arrow @click-left="onClickLeft" />
    <div class="img" :style="'height:' + (screeHeight - 50) + 'px;'" @click="yulan">
      <van-image :src="wsxc" fit="contain">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "Material",
  data() {
    return {
      wsxc: require("../assets/wsxc.png"), // 微商相册二维码
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0, 
      merchid: 0,
    };
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, "素材中心");
    
    this.init();
  },
  methods: {
    init() {
      // 初始化方法
      console.log("ddd");
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    yulan() {
      ImagePreview([this.wsxc]);
    }
  }
};
</script>
<style lang="less">
.material {
  height: 100%;
  width: 100%;
  position: relative;
  background: #ededed;
  z-index: 2;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
  }
  .img {
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: center;
    .van-image{
      display: block;
      height: 100%;
      width: auto;
    }
  }
}
</style>
